import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import appConfig from '../configs/appConfig';
import ClickableRegion from './ClickableRegion';
import { useFetchOrganization } from '../hooks/queries/useFetchOrganization';
import { useOrganizationId } from '../hooks/utils/useOrganizationId';
import { Text } from '@mantine/core';
import styles from './Footer.module.scss';
import { useOrganizationAgreement } from '../hooks/organization/useOrganizationAgreement';

const environment = appConfig.envLabel === 'Production' ? '' : `${appConfig.envLabel} `;
const currentVersion = appConfig.currentVersion;

const versionDisplay = `Version ${currentVersion}`;

export const Footer = () => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isSuccess: isSuccessCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const { data: agreementData, isSuccess: isSuccessOrganizationAgreement } =
    useOrganizationAgreement();

  const isLCMO =
    isSuccessCurrentOrganization &&
    isSuccessOrganizationAgreement &&
    fetchedCurrentOrganization.domains.includes('lmco.com') &&
    agreementData.data?.requiresAgreement;

  return (
    <footer>
      &copy;{' '}
      {t('app.copyright.display', {
        company: t('app.copyright.company'),
        year: new Date().getFullYear(),
        writ: t('app.copyright.writ'),
      })}{' '}
      <ClickableRegion
        regionLabel={t('global.api-docs')}
        href="https://api-docs.manifestcyber.com"
        className="footer-link"
        target="_blank"
      >
        {t('global.apiDocs')}
      </ClickableRegion>
      &bull;
      <ClickableRegion
        regionLabel={t('global.service-status')}
        href="https://status.manifestcyber.com"
        className="footer-link"
        target="_blank"
      >
        {t('global.service-status')}
      </ClickableRegion>
      &bull; &nbsp;
      {environment}
      {versionDisplay}
      {isLCMO && (
        <Text className={styles.orgNotes}>
          <Trans
            i18nKey="page.agreement.LCMOFooter"
            components={{
              lmpi: <span style={{ color: 'white', fontWeight: 700 }} />,
              tppi: <span style={{ color: 'white', fontWeight: 700 }} />,
            }}
          />
        </Text>
      )}
    </footer>
  );
};

export default Footer;
