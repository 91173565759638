import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Button, Drawer, Flex, Menu, Tooltip } from '@mantine/core';
import {
  MantineReactTable,
  MRT_PaginationState,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationInfo } from '../../../hooks/products/useFetchProducts';
import { UseProductsFilterResult } from '../../../hooks/products/useProductsFilter';
import Icon from '../../Icon';
import { getDefaultTableOptions } from '../../MRT/ManifestMRT';
import DataTableFooter from '../DataTableFooter';
import { ProductsTableFilters } from './ProductsTableFilter';

import { OnChangeFn, SortingState } from '@tanstack/react-table';
import { useSBOMDownload } from '../../../contexts/SBOMDownload.context';
import { useFetchProductCapabilities } from '../../../hooks/products/useFetchProductCapabilities';
import { ProductFormData } from '../../../hooks/products/usePostProduct';
import { useAuth } from '../../../hooks/useAuth';
import BasicSearch from '../../BasicSearch';
import { DeleteProductModal } from '../../Product/DeleteProductModal/DeleteProductModal';
import { ProductModal } from '../../Product/ProductModal/ProductModal';
import { Manufacturer } from './cells/Manufacturer';
import { Mitigate } from './cells/Mitigate';
import { ProductName } from './cells/ProductName';
import { TotalVulns } from './cells/TotalVulns';
import './ProductsTable.scss';

const defaultTableOptions = getDefaultTableOptions<InterfaceOrganizationProduct>();

interface ProductsTableProps {
  data: InterfaceOrganizationProduct[] | [];
  queryInfo: PaginationInfo;
  isLoading: boolean;
  isFetching: boolean;
  productsFilterHook: UseProductsFilterResult;
  pagination: MRT_PaginationState;
  setPagination: Dispatch<SetStateAction<MRT_PaginationState>>;
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
  data,
  queryInfo,
  isLoading,
  isFetching,
  productsFilterHook,
  pagination,
  setPagination,
  sorting,
  setSorting,
}: ProductsTableProps) => {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');

  const {
    filterSidebarOpen,
    setFilterSidebarOpen,
    filters,
    updateFilter,
    search,
    setSearch,
  } = productsFilterHook;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductFormData>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const columns = useMemo<MRT_ColumnDef<InterfaceOrganizationProduct>[]>(
    () => [
      {
        header: t('tables.products.headers.name'),
        accessorKey: 'name',
        Cell: ProductName,
      },
      {
        header: t('tables.products.headers.version'),
        accessorKey: 'version',
      },
      {
        header: t('tables.products.headers.manufacturer'),
        accessorKey: 'manufacturer',
        Cell: Manufacturer,
      },
      {
        header: t('tables.products.headers.assets'),
        accessorKey: 'assetCount',
      },
      {
        header: t('tables.products.headers.vulns'),
        accessorKey: 'countVulnerabilities.total',
        Cell: TotalVulns,
      },
      {
        header: t('tables.products.headers.mitigate-vulns'),
        accessorKey: 'countVulnerabilities.mitigate',
        Cell: Mitigate,
      },
    ],
    [],
  );

  const showSearchBar = false; // TODO: remove this flag when the search is implemented in backend
  const table = useMantineReactTable<InterfaceOrganizationProduct>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: data,
    enableFilters: false,
    enablePagination: false,
    enableRowActions: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: !!queryInfo?.totalCount ? queryInfo.totalCount : 0,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: true,
    },
    state: {
      globalFilter: search,
      pagination,
      sorting,
      isLoading: isLoading,
      showLoadingOverlay: isFetching,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    mantineSearchTextInputProps: {
      placeholder: t('tables.products.search'),
      sx: {
        minWidth: '260px',
      },
    },
    renderRowActionMenuItems: ({ row }) => {
      const setProduct = () => {
        setSelectedProduct({
          id: row.original._id?.toString() || '',
          name: row.original.name || '',
          /*types: row.original.types
            ? row.original.types.map((type) => type.toString())
            : [],*/
          description: row.original.description || '',
          manufacturer: row.original.manufacturer || '',
          version: row.original.version || '',
          labels: row.original.labelIds?.map((labelId) => labelId.toString()) as string[],
        });
      };

      const editProduct = () => {
        setProduct();
        setIsEditMode(true);
        handleOpenModal();
      };

      const deleteProduct = () => {
        setProduct();
        handleOpenDeleteModal();
      };

      const {
        isLoading: isLoadingProductCapabilities,
        error,
        data: capabilitiesData,
      } = useFetchProductCapabilities({
        productId: row.original._id?.toString(),
      });
      const { addToDownloadQueue, isLoading: isLoadingSBOMDownload } = useSBOMDownload();

      const unableToMergeReason = useMemo(() => {
        if (capabilitiesData?.capabilities.reason === 'asset formats mismatching')
          return t('product.unable-to-generate-sbom-formats-mismatching');
        if (capabilitiesData?.capabilities.reason === 'assets limit reached')
          return t('product.unable-to-generate-sbom-limit-reached');
        return '';
      }, [data]);

      return (
        <>
          <Tooltip
            disabled={capabilitiesData?.capabilities.canMerge || !unableToMergeReason}
            label={unableToMergeReason}
          >
            <span>
              <Menu.Item
                onClick={() => addToDownloadQueue(row.original)}
                icon={<Icon icon="arrow-down-to-bracket" />}
                disabled={
                  isLoadingSBOMDownload ||
                  isLoadingProductCapabilities ||
                  !capabilitiesData?.capabilities.canMerge
                }
              >
                {t('tables.products.actions.downloadSbom')}
              </Menu.Item>
            </span>
          </Tooltip>
          {hasWriteAccess && (
            <Menu.Item onClick={editProduct} icon={<Icon icon="pencil" />}>
              {t('tables.products.actions.edit')}
            </Menu.Item>
          )}
          {hasWriteAccess && (
            <Menu.Item onClick={deleteProduct} icon={<Icon icon="trash-can" />}>
              {t('tables.products.actions.delete')}
            </Menu.Item>
          )}
        </>
      );
    },
    renderTopToolbar: () => {
      return (
        <Flex justify={'space-between'} m="1rem 0">
          <Flex gap="xs">
            {showSearchBar && (
              <BasicSearch
                initialValue={search}
                handleSearch={setSearch}
                placeholder={t('tables.products.search')}
              />
            )}
            <Button
              size="sm"
              onClick={() => setFilterSidebarOpen(!filterSidebarOpen)}
              variant="default"
              leftIcon={<Icon icon="filter" />}
            >
              {t('global.filter')}
            </Button>
          </Flex>
          <Flex gap="xs">
            {hasWriteAccess && (
              <Button onClick={handleOpenModal}>{t('product.create-product')}</Button>
            )}
          </Flex>
        </Flex>
      );
    },
  });

  if (!data) {
    return null;
  }

  return (
    <div className="list-products">
      <MantineReactTable table={table} />
      <Drawer
        title={t('global.filters')}
        onClose={() => setFilterSidebarOpen(false)}
        opened={filterSidebarOpen}
        position="right"
        overlayProps={{ opacity: 0.3 }}
        size="xs"
        shadow="xl"
        styles={{
          inner: {
            top: '58px',
          },
          content: {
            border: 'solid 2px var(--color-bg-layout-stroke)',
            overflowX: 'hidden',
          },
        }}
      >
        <ProductsTableFilters filters={filters} updateFilter={updateFilter} />
      </Drawer>
      <ProductModal
        opened={isModalOpen}
        setOpened={setModalOpen}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        editProductData={selectedProduct}
      />
      <DeleteProductModal
        opened={isDeleteModalOpen}
        setOpened={setDeleteModalOpen}
        productData={selectedProduct}
      />
      <DataTableFooter
        currentPage={pagination.pageIndex}
        limitPerPage={pagination.pageSize}
        totalResults={queryInfo?.totalCount || 0}
        onChangePage={setPagination}
      />
    </div>
  );
};
