import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchManifestUser } from '../api/user/useFetchManifestUser';
import { useAuth } from '../hooks/useAuth';
import { Logger } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { UserMenu } from '../shared-portal/Layout/UserMenu/UserMenu';
import Footer from './Footer';
import { ManifestLogo } from './Icons/ManifestLogoIcon/ManifestLogoIcon';
import LeftNav from './LeftNav/LeftNav';
import Toolbar from './Toolbar/Toolbar';
import TopBar from './TopBar';
import { useOrganizationAgreement } from '../hooks/organization/useOrganizationAgreement';
import { useUserAgreementAcceptance } from '../hooks/organization/useUserAgreementAcceptance';
import AgreementComponent from '../components/Agreement/Agreement';
import { AxiosProxy } from '../api/axiosProxy/axiosProxy';
import { Loader } from '@mantine/core';

export const AuthedLayout = () => {
  const { user: savedUser, login, logout } = useAuth();
  const { data: fetchedUser, error: fetchUserError } = useFetchManifestUser();
  const { pathname } = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLeftSidebarExpanded, setIsLeftSidebarExpanded] = useState(false);
  const user = savedUser || fetchedUser;
  const toggleExpandSidebar = () => {
    setIsLeftSidebarExpanded(!isLeftSidebarExpanded);
  };

  const queryClient = useQueryClient();

  // State to track acceptance processing
  const [isAccepting, setIsAccepting] = useState(false);
  const [hasAcceptedAgreement, setHasAcceptedAgreement] = useState(false);

  // Fetch organization agreement
  const { data: agreementData, isLoading: isAgreementLoading } =
    useOrganizationAgreement();

  // Fetch user's agreement acceptance status
  const { data: userAcceptanceData, isLoading: isUserAcceptanceLoading } =
    useUserAgreementAcceptance();

  useEffect(() => {
    if (fetchedUser && !isEqual(savedUser, fetchedUser)) {
      login?.(fetchedUser, { shouldRedirect: false });
    }
  }, [fetchedUser, savedUser]);

  useEffect(() => {
    if (fetchUserError) {
      const statusCode = (fetchUserError as AxiosError)?.response?.status;
      const isUnauthorizedOrForbiddenError = Boolean(
        statusCode && [401, 403].includes(statusCode),
      );

      if (isUnauthorizedOrForbiddenError) {
        logout?.('expired');
      }

      Logger.captureExceptionWithMessage(
        'Error getting user profile in AuthedLayout',
        fetchUserError,
      );
    }
  }, [fetchUserError]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  // Check if user is logged in
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isAgreementLoading || isUserAcceptanceLoading) {
    return <Loader className="status-loader" speed="slow" size={18} />;
  }

  const requiresAgreement = agreementData?.data?.requiresAgreement;
  const agreementText = agreementData?.data?.agreementText || '';
  const agreementLastUpdated = agreementData?.data?.agreementLastUpdated;
  const organizationName = agreementData?.data?.organizationName;
  const userAcceptedAt = userAcceptanceData?.data?.agreementAcceptedAt;

  const needsToAcceptAgreement =
    requiresAgreement &&
    (!userAcceptedAt ||
      (agreementLastUpdated &&
        new Date(agreementLastUpdated) > new Date(userAcceptedAt)));

  if (needsToAcceptAgreement && !hasAcceptedAgreement) {
    return (
      <AgreementComponent
        agreementText={agreementText}
        orgName={organizationName}
        onAccept={async () => {
          // Update user's acceptance via PUT endpoint
          try {
            setIsAccepting(true);
            await AxiosProxy.put({
              url: 'organization/member/agreement',
              body: { agreementAcceptedAt: new Date().toISOString() },
            });
            // Invalidate user agreement acceptance query
            queryClient.invalidateQueries(['userAgreementAcceptance']);
            setHasAcceptedAgreement(true);
          } catch (error) {
            Logger.captureExceptionWithMessage(
              'Error updating agreement acceptance',
              error,
            );
            // Handle error (e.g., show a message to the user)
          } finally {
            setIsAccepting(false);
          }
        }}
        isAccepting={isAccepting}
      />
    );
  }

  // Existing logic
  if (!user.hasOrganizations) {
    return (
      <>
        <Toolbar
          withSidebar={false}
          isSidebarOpen={false}
          logo={<ManifestLogo height={30} width={120} />}
          rightSide={
            <UserMenu
              onLogOut={() => {
                navigate('/logout?reason=user-manual');
              }}
              texts={{
                logOut: t('navigation.logout.label'),
              }}
            />
          }
          actions={{
            goBack: () => navigate(-1),
            goBackLabel: t('global.goBack'),
            goForward: () => navigate(1),
            goForwardLabel: t('global.goForward'),
          }}
        />
        {/* Element just for testing purposes, we can remove when we have a better screen to show for non organization logged-in users */}
        <div data-testid="empty-page-non-org-user" />
      </>
    );
  }

  if (!user.isPaidOrgs && !pathname.includes('vendor-portal')) {
    return <Navigate to="/logout?reason=vendor-email-link-only" />;
  }

  return (
    <div className={`app-layout ${isLeftSidebarExpanded && 'left-sidebar-expanded'}`}>
      <LeftNav isPaidOrg={user.isPaidOrgs} toggleExpandSidebar={toggleExpandSidebar} />
      <TopBar isPaidOrg={user.isPaidOrgs} />
      <div className="page-content-area">
        {outlet}
        <Footer />
      </div>
    </div>
  );
};

export default AuthedLayout;
